<template>
  <!--      Header section start -->
  <Header />
  <!--      Header section end -->
  <SubMenu :title="categoryName"></SubMenu>
  <div class="container-fluid mb-lg-5">
    <div class="sub-category-container">
      <StartExamModal @close="toggleModal" @start="checkFields" :modalActive="modalActive"
        v-model:questionNumber="questionNumber" v-model:time="time" v-model:showErrorOfQnumber="showErrorOfQnumber"
        v-model:showErrorOfTime="showErrorOfTime" :title="title">

      </StartExamModal>
      <div class="sub-category-content">
        <div class="subjective-title-2 text-center">
          <h2 class="top-title-div" v-if="categoryName">{{categoryName}}</h2>
          <h2 class="top-title-div" v-else>সাব-ক্যাটাগরি</h2>
          <h2 class="top-sub-title" v-if="isExamMode">সব অধ্যায় এর একসাথে পরীক্ষা দিন অথবা আলাদা ভাবে দিন</h2>
          <select-all-button-view v-if="isExamMode" @click="selectALl" />
        </div>
        <div class="container-fluid">
          <div class="subjective-list">
            <BasicRow v-for="subcategory in subCategoryList" :key="subcategory.id" :name="subcategory.name" :sign="subcategory.sign" :questions_count="subcategory.questions_count"
                      :flowType="flowType" :marked ="subcategory.marked" @click="onSubCatClick(subcategory)">
            </BasicRow>
          </div>
          <div class="subjective-list-mobile">
            <BasicRowMobile v-for="subcategory in subCategoryList" :key="subcategory.id" :name="subcategory.name" :sign="subcategory.sign" :questions_count="subcategory.questions_count"
                            :flowType="flowType" :marked ="subcategory.marked" @click="onSubCatClick(subcategory)">
            </BasicRowMobile>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer />
    </div>
    <!--      Footer section end -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import BasicRow from "@/components/BasicRow.vue";
import { useRoute, useRouter, } from "vue-router";
import SelectAllButtonView from "@/components/SelectAllButtonView.vue";
import store from '@/store/index.js';
import { StaticTags, URLs } from "@/static";
import { calculateOffset } from "@/config/utils";
import StartExamModal from "@/components/StartExamModal.vue";
import { setExamInfo, setExamParams } from "@/config/utils.js"
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import Swal from 'sweetalert2/dist/sweetalert2';
import httpClient from "@/config/httpClient";
import BasicRowMobile from "@/components/BasicRowMobile.vue";
import SubMenu from "@/components/SubMenu.vue";


export default defineComponent({
  components: {SubMenu, BasicRowMobile, Footer, Header, BasicRow, SelectAllButtonView, StartExamModal },


  setup() {
    const router = useRouter();
    const route = useRoute();
    const categoryList = ref(store.getters["categoryList"])
    const flowType = store.state.flowType
    const isExamMode = ref(false)

    const questionNumber = ref(30)
    const time = ref(18)
    const showErrorOfTime = ref(false)
    const showErrorOfQnumber = ref(false)
    const modalActive = ref(false);

    const selectedSubCat = ref()
    const selectedCat = ref()
    const isExamOnSubCat = ref(true)
    const quiztime = ref(0)

    var categoryId = route.params.cid
    const subCategoryList = ref()
    const categoryName = ref()
    const title = ref()

    if (categoryList.value) {
      categoryList.value.forEach(element => {
        if (categoryId == element.id) {
          selectedCat.value = element
          subCategoryList.value = element.subcategories
          quiztime.value = element.quiz_time
          categoryName.value = element.name
          title.value = element.name
        }
      });
    }
    else {
      getSubCategoryList()
    }


    onMounted(() => {
      if (!flowType) {
        router.push({
          name: URLs.ROUTE_OOPS_PAGE_NAME,
        })
      }
      else if (flowType == StaticTags.TYPE_EXAM) {
        isExamMode.value = true
      }
    });


    const getSubCategoryList = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + '/categories/' + categoryId + "/subcategories"

      await httpClient
        .get(url)
        .then((res) => {
          if (res.success) {
            subCategoryList.value = res.subcategories
          }
          else {
            Swal.fire({
              text: res.message,
              title: "Failed",
              icon: StaticTags.ALERT_TYPE_ERROR
            })
          }
        })
    }

    const showModal = (subCategory) => {
      modalActive.value = true
      selectedSubCat.value = subCategory
    }

    const showModalForCategoryExam = () => {
      modalActive.value = true
      isExamOnSubCat.value = false
    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
    const onSubCatClick = (subCategory) => {
      if (flowType == StaticTags.TYPE_EXERCISE) {
        if (subCategory.chapters.length > 0) {
          goToChapters(subCategory)
        }
        else {
          setExamParams({
            topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
            topicId: subCategory.id
          })
          store.dispatch('setPageItemList', JSON.stringify(subCategory))
          router.push({
            name: URLs.ROUTE_PAGE_NAME,
            params: {
              cid: route.params.cid,
              sid: subCategory.id,
              chid: StaticTags.NO_ID,
            },

          })
        }
      }
      else if (flowType == StaticTags.TYPE_EXAM) {
        if (subCategory.chapters.length > 0) {
          goToChapters(subCategory)
        }
        else {
          showModal(subCategory)
          isExamOnSubCat.value = true
        }
      }
      else if (flowType == StaticTags.TYPE_FAVOURITE){
        goToPages(subCategory)
      }
      else {
        Swal.fire({
          text: "Failed!",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
      }
    }

    const goToPages = (subCategory) => {
      store.dispatch("setFlowType", StaticTags.TYPE_FAVOURITE)

      if (subCategory.marked == undefined || subCategory.marked <= 0) {
        Swal.fire({
          text: "No question marked!",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
      }
      // if needed to divide into page
      // else if (subCategory.marked > StaticTags.QUESTION_LIMIT_OF_PAGE) {
      else if (subCategory.chapters.length > 1) {
        router.push({
          name: URLs.ROUTE_FAVOURITE_CHAPTER_PAGE_NAME,
          params: {
            cid: categoryId,
            sid: subCategory.id
          }
        })
      }
      else if ( subCategory.marked > StaticTags.QUESTION_LIMIT_OF_PAGE ) {
        store.dispatch('setPageItemList', JSON.stringify(subCategory))
        setParamsInfo(subCategory)
        router.push({
          name: URLs.ROUTE_PAGE_NAME,
          params: {
            cid: route.params.cid,
            sid: subCategory.id,
            chid: StaticTags.NO_ID,
          }
        })
      }
      else {
        setParamsInfo(subCategory)
        // setExamInfo({
        //   id: subCategory.id,
        //   examType: StaticTags.EXAM_TYPE_FAVOURITE,
        //   title: subCategory.name,
        //   isViewOnly: false,
        //   questionNumber: subCategory.marked,
        //   isExam: true,
        //   startTime: new Date(),
        //   examFlowType: flowType
        // })
        // setExamParams({
        //   topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
        //   topicId: subCategory.id,
        //   questionNumber: subCategory.marked,
        // })
        router.push({
          name: URLs.ROUTE_EXAM_NAME,

        })
      }


    }

    const setParamsInfo = (subCategory) => {
      setExamInfo({
        id: subCategory.id,
        examType: StaticTags.EXAM_TYPE_FAVOURITE,
        title: subCategory.name,
        isViewOnly: false,
        questionNumber: subCategory.marked,
        isExam: true,
        startTime: new Date(),
        examFlowType: flowType
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
        topicId: subCategory.id,
        questionNumber: subCategory.marked,
      })
    }


    // const goToFavourite = (subCategory) => {
    //
    //   if (subCategory.marked == undefined || subCategory.marked <= 0) {
    //     Swal.fire({
    //       text: "No question marked!",
    //       title: "Failed",
    //       icon: StaticTags.ALERT_TYPE_ERROR
    //     })
    //   }
    //   // if needed to divide into page
    //   else if (subCategory.marked > StaticTags.QUESTION_LIMIT_OF_PAGE) {
    //     store.dispatch("setFlowType", StaticTags.TYPE_FAVOURITE)
    //     router.push({
    //       name: URLs.ROUTE_FAVOURITE_CHAPTER_PAGE_NAME,
    //       params: {
    //         cid: categoryId,
    //         sid: subCategory.id
    //
    //       }
    //     })
    //   } else {
    //     // setExamInfo({
    //     //   id: subCategory.id,
    //     //   examType: StaticTags.EXAM_TYPE_FAVOURITE,
    //     //   title: subCategory.name,
    //     //   isViewOnly: false,
    //     //   questionNumber: subCategory.marked,
    //     //   isExam: true,
    //     //   startTime: new Date(),
    //     //   time: time.value,
    //     //   examFlowType: flowType
    //     // })
    //     // setExamParams({
    //     //   topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
    //     //   topicId: subCategory.id,
    //     //   questionNumber: subCategory.marked
    //     // })
    //
    //     router.push({
    //       name: URLs.ROUTE_FAVOURITE_QUESTION_NAME,
    //     })
    //     // setExamParams({
    //     //   topicIds: [subCategory.id]
    //     // })
    //     // router.push({
    //     //   name: URLs.ROUTE_FAVOURITE_QUESTION_NAME,
    //     // })
    //   }
    //
    //
    // }

    const checkFields = () => {

      // check for validation of time and question number 
      // time 0.6 logic will be provided min 1 max result
      // category 100 - sub cate 50 chap 30 page 20
      var offset = calculateOffset(quiztime)

      var maxQ = Infinity
      if (isExamOnSubCat.value) {
        maxQ = Math.min(StaticTags.MAX_QUESTION_SUBCATEGORY, selectedSubCat.value.questions_count) // maxQ will be the minimum of 30 and question in the chapter
      }
      else {
        maxQ = Math.min(StaticTags.MAX_QUESTION_CATEGORY, selectedCat.value.questions_count) // maxQ will be the minimum of 50 and question in the sub category
      }

      if (questionNumber.value > maxQ) {
        showErrorOfQnumber.value = true
      }
      //else if for checking time

      else if (time.value > offset * maxQ) {
        showErrorOfTime.value = true
      }
      else {
        showErrorOfQnumber.value = false
        showErrorOfTime.value = false
        if (isExamOnSubCat.value) {
          startExamOnSubcategory()
        }
        else {
          startExamOnCategory()
        }
      }

    }

    const startExamOnSubcategory = () => {
      setExamInfo({
        id: selectedSubCat.value.id,
        examType: StaticTags.EXAM_TYPE_SUB_CATEGORY,
        title: selectedSubCat.value.name,
        isViewOnly: false,
        questionNumber: questionNumber.value,
        isExam: true,
        startTime: new Date(),
        time: time.value,
        examFlowType: flowType
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
        topicId: selectedSubCat.value.id,
        questionNumber: questionNumber.value
      })

      router.push({
        name: URLs.ROUTE_EXAM_NAME,
      })
    };

    const startExamOnCategory = () => {
      setExamInfo({
        id: selectedCat.value.id,
        examType: StaticTags.EXAM_TYPE_CATEGORY,
        title: selectedCat.value.name,
        isViewOnly: false,
        questionNumber: questionNumber.value,
        isExam: true,
        startTime: new Date(),
        time: time.value,
        examFlowType: flowType
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
        topicId: selectedCat.value.id,
        questionNumber: questionNumber.value
      })

      router.push("/exam");
    };



    const goToChapters = (subcategory) => {
      if (subcategory.chapters.length > 0) {
        if (flowType == StaticTags.TYPE_EXERCISE) {
          store.dispatch("setFlowType", StaticTags.TYPE_EXERCISE );
          router.push({
            name: URLs.ROUTE_EXERCISE_CHAPTER_PAGE_NAME,
            params: {
              cid: categoryId,
              sid: subcategory.id
            }
          })
        } else if (flowType == StaticTags.TYPE_EXAM) {
          store.dispatch("setFlowType", StaticTags.TYPE_EXAM );
          router.push({
            name: URLs.ROUTE_EXAM_CHAPTER_PAGE_NAME,
            params: {
              cid: categoryId,
              sid: subcategory.id
            }
          })
        }
      }
      else {
        //isModalVisible.value = true
      }
    };

    const selectALl = () => {
      showModalForCategoryExam()
    }


    return {
      goToChapters,
      selectALl,
      categoryId,
      subCategoryList,
      onSubCatClick,
      isExamMode,
      showModal,
      modalActive,
      toggleModal,
      questionNumber,
      time,
      checkFields,
      showErrorOfQnumber,
      showErrorOfTime,
      categoryName,
      flowType,
      title,
    };
  },
});
</script>

    

   

<style>
.sub-category-container {
  min-height: 50vh;
  justify-content: center;
  display: block;
}
</style>