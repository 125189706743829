<template>
  <Header />
    <div class="container-fluid">
        <div class="container-fluid pl-0 pr-0">
            <div v-if="exam" class="rank-list">
                <div class="col-lg-12 col-md-12 col-sm-12 banner-2 px-0 top-title-timer" id="download-app-section">
                    <div class="app-download-box-x">
                        <div class=" no-gutters align-items-center">
                            <div class="">
                                <div class="card-body">
                                    <h3 class="theme-color-x mb-0">{{ exam.title }}</h3>
                                </div>
                            </div>

                            <div class="">
                                <p>পরীক্ষার্থী: {{ exam.participants }} | উত্তীর্ণ: {{ exam.passed }} | কাট মার্ক: {{ exam.cut_mark }}
                                </p>
                            </div>

                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-lg-3 col-md-3 col-sm-3" ></div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <table class="leader-board-participants table table-responsive-md table-bordered text-center">
                            <thead>
                            <th class="bg-light text-dark "> নাম এবং মার্কস </th>
                            <th class="bg-light text-dark ">পজিশন</th>
                            </thead>
                            <tbody>
                            <tr class="text-left" v-for="(result, index) in results" :key="index">
                                <td>
                                    <span class="username">{{ result.user.name }}</span><br>
                                    <span><small>প্রাপ্ত মার্কস: {{ result.mark }} </small></span>
                                </td>
                                <td style="font-size: 18px;">
                                    <span> {{ result.position }} </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-3 col-md-3 col-sm-3" ></div>
                </div>
            </div>
        </div>

        <div style="padding-top: 40px">
        </div>
        <div ref="footer">
            <Footer />
        </div>
    </div>
</template>
  
<script>
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2/dist/sweetalert2';
import httpClient from "@/config/httpClient";
import { StaticTags } from "@/static";
import Footer from "@/components/FooterView.vue";
// import Header from "@/components/HeaderView.vue";
import Header from "@/components/NewHeaderView.vue";

export default {
    components: {
        Footer,
        Header,
    },
    setup() {

        const route = useRoute()
        const exam = ref(null)
        onMounted(() => {
            getRankList()
        })

        const getRankList = async () => {
            await httpClient
                .get(process.env.VUE_APP_API_BASE_URL + "/exams/" + route.params.id + "/top")
                .then((res) => {
                    if (res.success) {
                        exam.value = res.exam
                        results.value = res.results
                    }
                    else {
                        Swal.fire({
                            text: res.message,
                            title: "Failed",
                            icon: StaticTags.ALERT_TYPE_ERROR
                        })
                    }


                })
        }
        const results = ref([])

        return {
            results, 
            exam,
        }
    }
}
</script>
  
<style>

.leader-board-participants {
    margin: 20px 0 0 0;
    padding-left: 10px;
    padding-bottom: 10px;
}

.leader-board-participants tr {
    border-radius: 20px;
    padding: 5px 20px 5px 5px;
    margin-bottom: 10px;
    position: relative;
    background: #f1f1f1;
}

.leader-board-participants tr .username {
    display: inline-block;
    vertical-align: sub;
}

.leader-board-participants tr:first-child {
    background: #267fd8;
    color: #ffffff;
}

.leader-board-participants tr:nth-child(2) {
    background: #2694d8;
    color: #ffffff;
}

.leader-board-participants tr:nth-child(3) {
    background: #d88226;
    color: #ffffff;
}

.leader-board-participants tr .rank-circle {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: inline-block;
    background: #bfc9e038;
    text-align: center;
    margin-right: 15px;
    position: relative;
}
.leader-board-participants tr .rank-circle span {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    font-weight: bold;
}

.first {
    color: green;
}

.second {
    color: blue;
}

.third {
    color: orange;
}

.rank-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 1rem;
}

.card {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.card-text {
    margin-bottom: 0.25rem;
    font-size: 1rem;
}
</style>
  