export const ExamParams = {
    namespaced: true,
    state: {
      params : {
        topic: null,
        topicId: null,
        examId: null,
        page: null,
        questionNumber: null,
      }
    },
    actions: {
      setParams({ commit }, payload) {
        commit("setParam", payload);
      },
     
    },
    mutations: {
      setParams(state, params) {
        state.params = params;
      },
     
    },

    getters: {
      getExamParams: (state) => state.params,
  }
  };