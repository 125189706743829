<template>
  <div v-show="modalMenuActive" class="modall " :style="{top: `${coordinates.y}px`, left: `${coordinates.x}px`}">
    <div v-show="modalMenuActive">
      <div class="modal-dialog custom-modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="modal-content-syllabus">
              <div style="margin: 10px; ">
                <button type="button " style="width: 100%;" class="menu-button btn btn-outline-secondary" @click="onRankeListClicked">মেধা তালিকা</button>
              </div>
              <div style="margin: 10px">
                <button type="button" style="width: 100%;" class="menu-button btn btn-outline-secondary" @click="onQuestionListClicked">প্রশ্নপত্র</button>
              </div>
              <div style="margin: 10px">
                <button type="button" style="width: 100%;" class="menu-button btn btn-outline-danger" @click="closeMenuModal">বাতিল করুন</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import {StaticTags, URLs} from "@/static";
import { setExamInfo, setExamParams } from "@/config/utils";
import { useRouter } from "vue-router";

export default defineComponent({
  props: ["modalMenuActive", 'exam', 'coordinates'],
  setup(props, {emit}) {
    
    const router = useRouter();

    const closeMenuModal = () => {
      emit("close")
    }

    const onRankeListClicked = () => {
      router.push("/exam/"+props.exam.id+"/ranklist")

    }

    const onQuestionListClicked = () => {
      setExamParams({
        examId: props.exam.id
      })
      setExamInfo({
        id: props.exam.id,
        title: props.exam.title,
        examFlowType: StaticTags.TYPE_COURSE_EXAM_EXERCISE
      })
      router.push({name: URLs.ROUTE_EXAM_NAME})
    }

    return {
      closeMenuModal,
      onQuestionListClicked,
      onRankeListClicked,
    }
  }
})
</script>
  
<style lang="scss" scoped>

.menu-button {
  width: 145px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px !important;
}

.modall {
  display: flex;
  position: absolute;
  z-index: 1000;
}
</style>
  