<template>
  <Header/>

  <SubMenu :title="title"></SubMenu>
  <div class="container-fluid course-list-container">
    <div class="course-list-all">
      <div v-for="(item, index) in importantCourseTypes" :key="index" @click="handleClick(item)" class="card course-card cards text-left">
        <div v-if="item.running >= 1 && item.result_time != null" class="rectangle-top-right"><div class="top-tag-text"><i class="bi bi-broadcast"></i> Running </div></div>
        <div v-if="item.tag" class="rectangle-bottom-middle" :style="{background: item.tag_color}"><div class="bottom-tag-text">{{ item.tag }}</div></div>
        <div v-if="!item.image" class="card-img-top text-container item-container" :style="{background: item.color}">
          <div class="item-title ">{{ item.title }}</div>
        </div>
        <img v-else class="card-img-top item-container item-image" :src="item.image" :alt="item.title" style="cursor: pointer">
      </div>
<!--      <div-->
<!--          v-for="(item, index) in importantCourseTypes"-->
<!--          :key="index"-->
<!--          @click="handleClick(item)"-->
<!--          class="card  course-card cards text-left"-->
<!--      >-->
<!--        <div v-if="!item.image" class="card-img-top text-container item-container" :style="{ background: item.color }">-->
<!--          <div class="item-title ">{{ item.title }}</div>-->
<!--        </div>-->
<!--        <img v-else-->
<!--             class="card-img-top item-container item-image"-->
<!--             :src="item.image"-->
<!--             :alt="item.title"-->
<!--             style="cursor: pointer"-->
<!--        >-->
<!--      </div>-->
    </div>
  </div>

  <div class="container-fluid">
    <div ref="footer">
      <Footer/>
    </div>
  </div>

</template>

<script>

import httpClient from "@/config/httpClient";
import {defineComponent, ref, onMounted} from "vue";
import {StaticTags, URLs} from "@/static";
import {useRoute, useRouter} from "vue-router";
import Swal from 'sweetalert2/dist/sweetalert2';
import store from '@/store/index.js';
// import {Pagination, Carousel, Slide} from "vue3-carousel";
import 'vue3-carousel/dist/carousel.css'
import Header from "@/components/NewHeaderView.vue";
import SubMenu from "@/components/SubMenu.vue";
import Footer from "@/components/FooterView.vue";

export default defineComponent({
  components: {Footer, SubMenu, Header},


  setup() {
    const importantCourseTypes = ref([])
    const router = useRouter();
    const route = useRoute();
    const totalLabel = ref("All Course Types")
    const title = ref(null)
    const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
    const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)

    onMounted(() => {
      // console.log(importantCourseTypes);
      title.value = store.state.titleBar ? store.state.titleBar : "কোর্স সমূহ"
      getCourseTypes()
    });

    const handleClick = (item) => {

      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_NORMAL_COURSE)
      router.push({
        name: URLs.ROUTE_COURSE_EXAM_LIST_NAME,
        params: {
          exam_type: item.id,

        },
      })

    };
    const handleAllButtonClick = () => {

      store.dispatch(StaticTags.DISPATCH_ID_FLOW_TYPE, StaticTags.TYPE_NORMAL_COURSE)
      router.push({
        name: URLs.ROUTE_COURSE_TYPES_ALL_NAME,
      })

    };
    const getCourseTypes = async () => {
      console.log("Params::", route.params)
      const url = route.params.id == StaticTags.NO_ID ? process.env.VUE_APP_API_BASE_URL + "/exam-types" : process.env.VUE_APP_API_BASE_URL + "/course-types/" + route.params.id + "/exam-types";
      const config = {
        params: {
          creator_id: -1,  // -1 for important courses documented in the api
        },
      };
      console.log("URL", url)
      if (isLoggedIn) {
        await httpClient
            .get(url, config)
            .then((res) => {
              console.log("Courses:::", res)
              if (res.success) {
                importantCourseTypes.value = res.exam_types;
              } else {
                Swal.fire({
                  text: res.message,
                  title: "Failed",
                  icon: StaticTags.ALERT_TYPE_ERROR
                })
              }

            })
      }
    }


    return {
      handleClick,
      importantCourseTypes,
      totalLabel,
      handleAllButtonClick,
      isLoggedIn,
      title,

    };
  }
});
</script>

<style scoped>
.rectangle-top-right {
  position: absolute;
  top: 10px;
  right: 10px;
  height: max-content;
  width: 35%;
  background-color: #0b0a0a;
  border-radius: 18px;
}
.rectangle-bottom-middle {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  height: fit-content;
  width: max-content;
  border-radius: 2px;
  padding: 1px;
}
.bottom-tag-text {
  font-size: 10px;
  color: white;
  font-weight: 600;
  width: max-content;
}
.top-tag-text {
  text-align: center;
  color: red;
  font-size: x-small;
  font-weight: 600;
  line-height: 22px;
}


.course-list-container {
  min-height: 62vh;
}

.course-list-all {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.item-container {
  height: 320px;
  width: 217px;
  overflow: hidden; /* if you want to hide the overflow, or you could also use 'scroll' or 'auto' */
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.item-image {
  object-fit: cover;
}

.item-title {
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  color: white;
  font-size: 23px;
  text-align: center;
  line-height: 32px;
  font-weight: 600;
}

.slide-item {
  margin: 0 10px;
}

@media (min-width: 768px) {
  .course-list-all {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
}

@media (max-width: 768px) {
  .card-img-top {
    height: 225px;
    width: 150px;
  }

  .item-title {
    font-size: 18px;
    margin-top: 15px;
  }
}

.scroll-view {
  margin-bottom: 1rem;
}

.scroll-wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
}

.scroll-container {
  display: inline-block;
}

.scroll-item {
  display: inline-block;
  margin-right: 0.5rem;
}

.image-button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

.image-button img {
  max-width: 100%;
  height: auto;
  display: block;
  border-radius: 5%;
}

.total-button {
  margin-top: 0.5rem;
}

@media (min-width: 992px) {
  .col-course {
    flex: 0 0 16.666667%;
    max-width: 20%;
  }
}

@media (max-width: 768px) {
  .col-course {
    flex: 0 0 16.666667%;
    max-width: 33%;
  }
}

</style>
