<template>

  <transition name="modal-animation">
    <div v-show="modalActive" class="modal" >
      <transition name="modal-animation-inner">
        <div v-show="modalActive">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <div class="modal-content-title-section">
                  <h2 class="modal-content-title">{{title}}</h2>
                </div>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div class="col-sm-6 time-field">
                    <label class="text-left" for="time-field"><b>মিনিট </b> (ম্যাক্স ১৮) </label>
                    <input class="input-field"
                           type="number"
                           id="time-field"
                           :min="3"
                           :max="18"
                           :value="time"
                           @input="$emit('update:time', +$event.target.value)" />
                    <p v-if="showErrorOfTime">Time Value Error</p>
                  </div>
                  <div class="col-sm-6 question-number-field">
                    <label class="text-left" for="question-number"><b>প্রশ্ন</b> (মিন ১০, ম্যাক্স ৩০) </label>
                    <input class="input-field"
                           type="number"
                           id="question-number"
                           :value="questionNumber"
                           :min="10"
                           :max="30"
                           @input="$emit('update:questionNumber', +$event.target.value)" />
                    <p v-if="showErrorOfQnumber" class="text-red">Question Number Error</p>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button class="close-btn btn btn-outline-danger" @click="close" type="button">বাতিল করুন</button>
                <button class="start-btn btn btn-outline-primary" @click="start" type="button">শুরু করুন</button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  props:["modalActive","title" ,"questionNumber", "selectedChapter","time","showErrorOfQnumber","showErrorOfTime"],
  setup(props, { emit }) {
    const close = () => {
      emit("close");
    };
    const start = () => {
      emit("start")
    }
    return { close,start };
  },
};
</script>

<style lang="scss" scoped>
.modal-content-syllabus-2 {
  text-align: justify;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 15px;
}
.time-field {
  display: grid;
}
.question-number-field {
  display: grid;
}

.input-field {
  width: 200px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 1px solid #000;
  background: #F0F5F9;
}

.modal-content {
  border-radius: 24px;
}
.close-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}
.start-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}

.modal-header {
  text-align: center;
  justify-content: center;
  border-bottom: 0px solid #e9ecef;
}
.modal-footer {
  text-align: center;
  justify-content: center;
  border-top: 0px solid #e9ecef;
}
.modal-body {
  padding: 2rem;
}
.modal-content-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
}
.content-sub-title {
  color: #00A8A8;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
}
.syllabus-title {
  color: #2f2d2d;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 60% */
  text-align: start;
}
.syllabus-content {
  color: #2f2d2d;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(14 13 13 / 71%);
}

.modal-dialog {
  width: auto;
  height: 662px;
}

@media (min-width: 576px){
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }

}
</style>