<template>
  <div class="mx-auto mb-3">
    <div class="counter-box">
      <h2 class="counter-text text-left">টাইমার</h2>
      <p class="counter-time text-right">{{ englishToBengaliNumber(minutes) }}:{{ englishToBengaliNumber(seconds) }}</p>
    </div>
  </div>
</template>

<script>
import {ref, watchEffect, defineComponent} from 'vue';
import { englishToBengaliNumber } from "@/config/utils";

export default defineComponent({
  props: ["maxCount", 'finishCount', 'counter'],

  setup(props, context) {
    const minutes = ref(Math.floor(props.maxCount / 60));
    const seconds = ref(props.maxCount % 60);
    const progress = ref(0);
    const circumference = ref(2 * Math.PI * 48);
    let timerId;
    const count = ref(0)

    const startTimer = () => {
      timerId = setInterval(() => {
        if (seconds.value === 0) {
          if (minutes.value === 0) {
            clearInterval(timerId);
            finish()
            return;
          }
          context.emit('update:counter', count.value++)
          minutes.value--;
          seconds.value = 59;
        } else {
          seconds.value--;
        }
        progress.value = (1 - (seconds.value + minutes.value * 60) / props.totalTime) * circumference.value;
      }, 1000);
    };

    watchEffect(() => {
      if (props.maxCount > 0) {
        startTimer();
      }
    });

    const finish = () => {
      context.emit("finishCount")
    }
    return {
      minutes,
      seconds,
      progress,
      circumference,
      englishToBengaliNumber,
    };
  },
});
</script>

<style scoped>
.counter-time {
  color: #00A8A8;
  font-family: Noto Sans Bengali;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 85.714% */
  padding: 10px;
}

.counter-text {
  color: #2A2B2B;
  font-family: Noto Sans Bengali;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 120% */
  padding: 10px;
  display: none;
}
.counter-box {
  width: fit-content;
  height: fit-content;
  background-color: #fff0;
  background-clip: border-box;
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0px;
}

@media (min-width: 768px) {
  .counter-box {
    margin-top: 1rem;
    width: 240px;
    height: 60px;
    background-color: #fff;
    background-clip: border-box;
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    align-items: center;
    position: fixed;
    right: 122px;
  }
  .counter-text {
    display: grid;
  }
}

</style>