import httpClient from "./httpClient";

const actionScope = `loader`;
import store from '@/store/index.js';
import {StaticTags, URLs} from "@/static";
import Swal from 'sweetalert2/dist/sweetalert2';
import router from '@/router'
import {  removeItem, } from "@/config/utils";

export function setupInterceptors({dispatch}) {


  let requestsPending = 0;
  const req = {
    pending: () => {
      requestsPending++;
      dispatch(`${actionScope}/show`);
    },
    done: () => {
      requestsPending--;
      if (requestsPending <= 0) {
        dispatch(`${actionScope}/hide`);
      }
    }
  };

    /**
     * set api token in header in every api call
     */
    httpClient.interceptors.request.use(
        config => {
            req.pending();
            const token = store.getters[StaticTags.GET_ACCESS_TOKEN];

            if (token) {
                config.headers["api-token"] = token; //set Auth token
            }
            return config;
        },
        error => {
            requestsPending--;
            req.done();
            return Promise.reject(error);
        }
    );

    /**
     * check every response , if success = false then show error otherwise return success data
     */
    httpClient.interceptors.response.use(
        ({data}) => {
            req.done();
            if (data == undefined) {
                Swal.fire({
                    text: "",
                    title: "Failed",
                    icon: StaticTags.ALERT_TYPE_ERROR
                })
            } else {
                return Promise.resolve(data);
            }


        },
        error => {
            req.done();


            // if (error.config.hasOwnProperty('errorHandle') && error.config.errorHandle === false) {
            //   return Promise.reject(error);
            // }

            // check for status check
            if (error.response.status == 401) {
                removeItem(StaticTags.IS_LOGGED_IN)
                store.commit("setAcessToken", null)
                router.push("/login/")
            }
            if (error.response.status == 404) {
            console.log("ERROR 404: ", error.response)
            }

            if (error.response.status == 412) {
                console.log("need to buy course")
                Swal.fire({
                    text: "আপনার এই কোর্স/ফিচারে এক্সেস নেই। ফুল এক্সেস পেতে বর্তমান প্যাকেজ আপগ্রেড করুন।",
                    title: "আপগ্রেড প্যাকেজ",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#f6071d',
                    confirmButtonText: "আপগ্রেড করুন",
                    cancelButtonText: "বাতিল করুন"
                }).then((result) => {
                    if (result.isConfirmed) {
                        store.dispatch("setTitleBar", "প্যাকেজ")
                        router.push({
                            name: URLs.ROUTE_PACKAGES_NAME
                        });
                    }else {
                        router.go(-1);
                    }
                })
            }
        }
    );
}