<template>
    <div class="result-box">
      <div class="result-content-1">
        <p>নম্বর: {{englishToBengaliNumber(exam.result.mark)}}/{{ englishToBengaliNumber(exam.total) }},  পজিশন: {{ englishToBengaliNumber(exam.result.position)}} {{ exam.result.late==1? "[Late]":""}}</p>
      </div>
      <div class="result-content-2">
        <p>উত্তীর্ণ: {{ englishToBengaliNumber(exam.passed) }}/{{ englishToBengaliNumber(exam.participants) }} </p> <p>   কাট নম্বর: {{ englishToBengaliNumber(exam.cut_mark) }},    সর্বোচ্চ: {{ englishToBengaliNumber(exam.best) }}</p>
      </div>
    </div>
  </template>
  
  <script>
import { defineComponent } from 'vue';

  export default defineComponent({
    props: ["exam",],
    setup() {
      const englishToBengaliNumber = (number) => {
        const bengaliNumbers = {
          '0': '০',
          '1': '১',
          '2': '২',
          '3': '৩',
          '4': '৪',
          '5': '৫',
          '6': '৬',
          '7': '৭',
          '8': '৮',
          '9': '৯'
        };
        const englishNumber = String(number);
        const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

        return bengaliNumber;
      }

      return {
        englishToBengaliNumber
      }
    }

})
  </script>
  
  <style>
  .result-box {
    margin-top: 1rem;
    margin-left: 1rem;
    width: 297px;
    height: 140px;
    fill: #FFF;
    stroke-width: 1px;
    stroke: rgba(211, 216, 220, 0.30);
    background-color: #fff;
    background-clip: border-box;
    padding: 10px;
  }
  </style>
  