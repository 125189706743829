
export default class Question {
    constructor(id, title, option1,option2,option3, option4, correctAnswer, explanation) {
        this.id = id
        this.title = title
        this.option1 = option1
        this.option2 = option2
        this.option3 = option3
        this.option4 = option4
        this.correctAnswer = correctAnswer
        this.explanation = explanation
    }
}