<template>
  <!--      Header section start -->
  <Header />
  <!--      Header section end -->
  <SubMenu :title="pageHeader"></SubMenu>

  <div class="container-fluid mb-lg-5">
    <div class="subjective-test-container">
      <div class="subjective-title-2 text-center" >
        <h2 class="top-title-div" v-if="pageHeader">{{pageHeader}}</h2>
        <h2 class="top-title-div" v-else>{{pageHeader}}Pages</h2>
      </div>
      <div class="subjective-list">
        <BasicRow v-for="page in pages.slice()" :key="page.id" :name="page.pageName" :questions_count="page.count" :sign="page.sign"
                  :from_page="true" :pageText="page.pageCountText" @click="onPageClick(page)">
        </BasicRow>
      </div>

      <div class="subjective-list-mobile">
        <BasicRowMobile v-for="page in pages.slice()" :key="page.id" :name="page.pageName"
                        :questions_count="page.count" :sign="page.sign" :from_page="true" :pageText="page.pageCountText"
                        @click="onPageClick(page)">
        </BasicRowMobile>
      </div>
    </div>
    <div class="container-fluid">
      <!--      Footer section start -->
      <div ref="footer">
        <Footer />
      </div>
      <!--      Footer section end -->
    </div>
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import BasicRow from "@/components/BasicRow.vue";
import { useRouter } from "vue-router";
import store from '@/store/index.js';
import { StaticTags, URLs } from "@/static";
import { setExamInfo, setExamParams } from "@/config/utils.js"
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import BasicRowMobile from "@/components/BasicRowMobile.vue";
import { englishToBengaliNumber } from "@/config/utils";
import SubMenu from "@/components/SubMenu.vue";
export default defineComponent({
  components: {SubMenu, BasicRowMobile, Footer, Header, BasicRow },

  setup() {
    const router = useRouter();
    // const route = useRoute();
    const categoryList = store.getters[StaticTags.GET_CATEGORY_LIST]
    let flowType = store.state.flowType
    const pageHeader = ref(null)
    if (!categoryList) {
      router.push({
          name: URLs.ROUTE_HOME_NAME
        })
    }
    // var chapterId = route.params.chid
    //var subCategoryId = route.params.sid
    // var categoryId = route.params.cid
    var pages = ref([])
    var itemList = null

    onMounted(() => {
      if (!flowType) {
        router.push({
          name: URLs.ROUTE_OOPS_PAGE_NAME
        })
      }

      try {
        itemList = JSON.parse(store.getters[StaticTags.GET_PAGE_ITEM_LIST])
        pageHeader.value = itemList.name
        createPage(itemList)
      } catch (error) {
        console.log(error);
        router.push({
          name: URLs.ROUTE_OOPS_PAGE_NAME
        })
      }
    });

    const onPageClick = (page) => {
      if (flowType == StaticTags.TYPE_EXERCISE) {
        setExamInfo({
          id: page.pageId,
          title: pageHeader.value + " " + page.pageName,
          isViewOnly: true,
          questionNumber: page.questionNumber,
          isExam: false,
          examFlowType: flowType
        })
        setExamParams({
          questionNumber: page.count,
          page: page.pageId,
        })
      }
      else if (flowType == StaticTags.TYPE_FAVOURITE) {
        setExamInfo({
          title: pageHeader.value + " " + page.pageName,
          isViewOnly: true,
          questionNumber: page.questionNumber,
          isExam: false,
          examFlowType: flowType
        })
        setExamParams({
          page: page.pageId,
          questionNumber: page.count
        })
      }
      router.push({
        name: URLs.ROUTE_EXAM_NAME,
      })
    }


    // logic behind defining number of page
    const createPage = (itemList) => {
      const questionsCount = flowType === StaticTags.TYPE_FAVOURITE ? itemList.marked : itemList.questions_count
      let res = parseInt(questionsCount / StaticTags.QUESTION_LIMIT_OF_PAGE)
      let rem = questionsCount % StaticTags.QUESTION_LIMIT_OF_PAGE

      let i = 0
      let questionNumber = 1
      for (i = 0; i < res; i++) {
        pages.value.push({
          pageId: i,
          pageName: "পেজ " + englishToBengaliNumber(i + 1),
          name: itemList.name,
          count: StaticTags.QUESTION_LIMIT_OF_PAGE,
          pageCountText: englishToBengaliNumber(questionNumber) + " - " + englishToBengaliNumber(questionNumber + StaticTags.QUESTION_LIMIT_OF_PAGE - 1) + " তম প্রশ্ন"
        })
        questionNumber += StaticTags.QUESTION_LIMIT_OF_PAGE
      }
      if (rem > 0) {
        pages.value.push({
          pageId: i,
          pageName: "পেজ " + englishToBengaliNumber(i + 1),
          name: itemList.name,
          count: rem,
          pageCountText: englishToBengaliNumber(questionNumber) + " - " + englishToBengaliNumber(questionNumber + rem - 1) + " তম প্রশ্ন"
        })
      }
    }

    return {
      pages,
      onPageClick,
      pageHeader,
    };
  },
});
</script>
  
      
  
     
  
<style>

</style>