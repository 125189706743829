<template>
  <div class="question-menu">
    <p @click="toggleShowMenue" style="cursor: pointer">...</p>
    <div v-if="showMenuList" class="question-menu-items">
      <div v-for="(item, index) in reactiveMenuItems" :key="index"
           @click="$emit('onClickOption', item.action)"
           class="question-menu-item">{{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
import {computed,} from 'vue';

export default {
  props: {
    menuItems: {
      type: Array,
      default: () => [],
    },
    showMenuList: {
      type: Boolean,
      default: () => false,
    },
  },
  setup(props, context) {


    const toggleShowMenue = () => {
      context.emit('toggleShowMenueList')

    }

    const reactiveMenuItems = computed(() => {
      return props.menuItems.slice(); // create a new array to make it reactive
    });

    return {reactiveMenuItems, toggleShowMenue};
  },
};
</script>

<style scoped>
.question-menu {
  position: relative;
}

.question-menu-items {
  position: absolute;
  top: 30px;
  right: 0;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: max-content;
  text-align: justify;
}

.question-menu-item {
  margin: 5px 0;
  background-color: #F4F6F6;

}
</style>