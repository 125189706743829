<template>
    <div>
        <div v-if="isLoggedIn && userDetails">
            <UpdateProfile :show-modal="showModal" :user="updateAbleuser" @on-close="showModal = false"
                @on-update="updateUser" />
        </div>
        <div v-if="userDetails && isLoggedIn">
            <table class="table table-borderless">
                <thead>
                    <tr>
                        <th class="text-left" scope="col">{{ userDetails.name }}</th>
                    </tr>
                    <tr>
                        <th class="text-left" scope="col">ID: {{ userDetails.id }} | {{ userDetails.userid }}</th>
                    </tr>
                    <tr v-if="userDetails.membership == 'ACTIVE'">
                        <th class="text-left" scope="col"> একটিভ | মেয়াদ: {{ userDetails.end_date }}</th>
                    </tr>
                    <tr v-if="userDetails.membership != 'ACTIVE'">
                        <th class="text-left" style="color: red" scope="col"> কোন একটিভ প্যাকেজ নাই </th>
                    </tr>
                </thead>
                <tbody>
                </tbody>
            </table>
            <button @click="showModal = true">Edit Profile</button>

            <div>
                <AllExamState :interval="interval" />
                <EverydayState :interval="interval" />
                <SubjectState :interval="interval" />

            </div>

        </div>

    </div>
</template>

<script>
import store from '@/store/index.js';
import { removeItem, setStore } from "@/config/utils";
import { StaticTags } from "@/static";
import { useRouter } from "vue-router";
// import Swal from 'sweetalert2/dist/sweetalert2';
import { onMounted, ref, defineComponent } from "vue";
import httpClient from "@/config/httpClient";
import AllExamState from "@/components/Profile/AllExamState.vue"
import EverydayState from "@/components/Profile/EverydayState.vue"
import SubjectState from "@/components/Profile/SubjectState.vue"
import UpdateProfile from './UpdateProfile.vue';
import Swal from 'sweetalert2/dist/sweetalert2';


export default defineComponent({
    components: {
        AllExamState,
        EverydayState,
        SubjectState,
        UpdateProfile
    },
    setup() {
        const router = useRouter();
        const access_token = store.getters[StaticTags.GET_ACCESS_TOKEN];
        const isLoggedIn = (access_token && access_token != "null" && access_token != undefined)
        const userDetails = ref(null)
        const interval = ref(30) // interval range in days
        const showModal = ref(false);

        const updateAbleuser = ref();

        const updateUser = async (updatedUser) => {
            updateAbleuser.value = { ...updatedUser };

            const userInfo = {
                name: updateAbleuser.value.name,
                mobile: updateAbleuser.value.mobile,
                email: updateAbleuser.value.email,
                website: true,

            }
            await httpClient
                //.post(baseUrl + "api2/login/", loginParams)
                .post(process.env.VUE_APP_API_BASE_URL + "/reset-password-request", userInfo)
                .then((response) => {
                    if (response != undefined) {
                        if (response.success) {
                            showModal.value = false
                            getUserDetails()
                        }
                        else {
                            Swal.fire({
                                text: response.message,
                                title: "Failed",
                                icon: StaticTags.ALERT_TYPE_ERROR
                            })
                        }
                    }

                })
        };


        onMounted(() => {
            if (isLoggedIn) {
                getUserDetails()
            }

        })




        const getUserDetails = async () => {
            await httpClient
                //.post(baseUrl + "api2/login/", loginParams)
                .get(process.env.VUE_APP_API_BASE_URL + "/user-info")
                .then((response) => {
                    if (response != undefined) {
                        if (response.success) {
                            userDetails.value = response.user
                            updateAbleuser.value = {
                                name: userDetails.value.name,
                                phone: userDetails.value.mobile,
                                userId: userDetails.value.id,
                                email: userDetails.value.email
                            }
                            setStore(StaticTags.USER_DETAILS, response.user)
                        }

                    }

                })
        }
        //const userDetails = JSON.parse(getStore(StaticTags.USER_DETAILS))
        // if (userDetails == undefined || userDetails == null || userDetails == "null") {
        //   Swal.fire({
        //     text: "No User Found",
        //     title: "Failed",
        //     icon: StaticTags.ALERT_TYPE_ERROR,
        //     confirmButtonColor: '#3085d6',
        //     cancelButtonColor: '#d33',
        //     confirmButtonText: 'Reload'
        //   }).then((result) => {
        //     if (result.isConfirmed) {
        //       router.go()
        //     }
        //   })
        //
        // }
        function logout() {
            removeItem(StaticTags.IS_LOGGED_IN)
            store.commit("setAcessToken", null)
            router.push("/login/")
        }


        return {
            logout,
            isLoggedIn,
            userDetails,
            interval,
            updateAbleuser,
            showModal,
            updateUser,
        }
    }
});
</script>

<style></style>
