<template>
  <!--      Header section start -->
  <Header />
  <!--      Header section end -->
  <SubMenu :title="chapterName"></SubMenu>
  <div>
    <div>
      <StartExamModal @close="toggleModal" @start="checkFields" :modalActive="modalActive"
        v-model:questionNumber="questionNumber" v-model:time="time" v-model:showErrorOfQnumber="showErrorOfQnumber"
        v-model:showErrorOfTime="showErrorOfTime" :title="title">

      </StartExamModal>
      <div class="container-fluid mb-lg-5">
        <div class="subjective-test-container">
          <div class="subjective-title-2 text-center" >
            <h2 class="top-title-div" v-if="chapterName">{{chapterName}}</h2>
            <h2 class="top-title-div" v-else>অধ্যায়</h2>
            <h2 class="top-sub-title" v-if="isExamMode">সব অধ্যায় এর একসাথে পরীক্ষা দিন অথবা আলাদা ভাবে দিন</h2>
            <select-all-button-view v-if="isExamMode" @click="selectALl" />
          </div>
          <div class="subjective-list">
            <BasicRow v-for="chapter in chapterList" :key="chapter.id" :name="chapter.name" :questions_count="chapter.questions_count" :sign="chapter.sign"
                      :flowType="flowType" :marked ="chapter.marked" @click="onChapterClick(chapter)">
            </BasicRow>
          </div>

          <div class="subjective-list-mobile">
            <BasicRowMobile v-for="chapter in chapterList" :key="chapter.id" :name="chapter.name"
                            :questions_count="chapter.questions_count" :sign="chapter.sign" :flowType="flowType" :marked ="chapter.marked"
                            @click="onChapterClick(chapter)">
            </BasicRowMobile>
          </div>

          <div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer />
    </div>
    <!--      Footer section end -->
  </div>
</template>
<script>
import { defineComponent, onMounted, ref } from "vue";
import BasicRow from "@/components/BasicRow.vue";
import { useRouter, useRoute } from "vue-router";
import store from '@/store/index.js';
import { StaticTags, URLs } from "@/static";
//import {StaticTags} from '@/static'
import { setExamInfo, setExamParams } from "@/config/utils.js"
import Swal from 'sweetalert2/dist/sweetalert2';
import httpClient from "@/config/httpClient";
import StartExamModal from "@/components/StartExamModal.vue";
import { calculateOffset } from "@/config/utils";
import SelectAllButtonView from "@/components/SelectAllButtonView.vue";
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import BasicRowMobile from "@/components/BasicRowMobile.vue";
import SubMenu from "@/components/SubMenu.vue";
export default defineComponent({
  components: {SubMenu, BasicRowMobile, Footer, Header, BasicRow, StartExamModal, SelectAllButtonView },

  setup() {

    const router = useRouter();
    const route = useRoute();
    const questionNumber = ref(30)
    const time = ref(18)
    const categoryList = store.getters[StaticTags.GET_CATEGORY_LIST]
    let chapterList = ref([]);
    const selectedChapter = ref(null)
    const selectedSubCat = ref()

    const showErrorOfTime = ref(false)
    const showErrorOfQnumber = ref(false)
    const modalActive = ref(false);


    let flowType = store.state.flowType
    const isExamOnChap = ref(true)
    const isExamMode = ref(false)
    const quiztime = ref(0)
    var subCategoryId = route.params.sid
    const chapterName = ref()
    const title = ref()


    if (categoryList) {
      categoryList.forEach(element => {
        element.subcategories.forEach(sub => {
          if (subCategoryId == sub.id) {
            selectedSubCat.value = sub
            chapterList.value = sub.chapters
            quiztime.value = element.quiz_time
            chapterName.value = sub.name
            title.value = sub.name
          }
        })
      });
    }
    else {
      getChapters()
    }

    onMounted(() => {
      if (!flowType) {
        router.push({
          name: URLs.ROUTE_OOPS_PAGE_NAME,
        })
      }
      else if (flowType == StaticTags.TYPE_EXAM) {
        isExamMode.value = true
      }
    });
    const onChapterClick = (chapter) => {
      title.value = chapter.name
      // check for mode of exam // if in exercise mode go to page or go to question page
      if (flowType == StaticTags.TYPE_EXERCISE) {
        selectedChapter.value = chapter
        if (selectedChapter.value.questions_count > StaticTags.QUESTION_LIMIT_OF_PAGE) {
          goToPages(chapter)
        }
        else {
          startExerciseOnChapter(chapter)
        }
      }
      else if (flowType == StaticTags.TYPE_EXAM) {
        selectedChapter.value = chapter
        if (selectedChapter.value.questions_count > StaticTags.MAX_QUESTION_CHAPTERS) {
          showModal(chapter)
          isExamOnChap.value = true
        }
      }
      else if (flowType == StaticTags.TYPE_FAVOURITE) {
        selectedChapter.value = chapter
        if (selectedChapter.value.marked > StaticTags.QUESTION_LIMIT_OF_PAGE) {
          goToPages(chapter)
        }
        else {
          startFavouriteOnChapter(chapter)
        }
      }

    }

    const getChapters = async () => {
      const url = process.env.VUE_APP_API_BASE_URL + '/subcategories/' + subCategoryId + "/chapters"

      await httpClient
        .get(url)
        .then((res) => {
          if (res.success)
            chapterList.value = res.chapters
          else {
            Swal.fire({
              text: res.message,
              title: "Failed",
              icon: StaticTags.ALERT_TYPE_ERROR
            })
          }
        })
    }

    const showModal = (chapter) => {
      modalActive.value = true
      selectedChapter.value = chapter

    }

    const showModalForSubCategoryExam = () => {
      modalActive.value = true
      isExamOnChap.value = false
    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };


    const selectALl = () => {
      showModalForSubCategoryExam()
    }

    const checkFields = () => {

      // check for validation of time and question number 
      // time 0.6 logic will be provided min 1 max result
      // category 100 - sub cate 50 chap 30 page 20

      var offset = calculateOffset(quiztime)
      var maxQ = Infinity
      if (isExamOnChap.value) {
        maxQ = Math.min(StaticTags.MAX_QUESTION_CHAPTERS, selectedChapter.value.questions_count) // maxQ will be the minimum of 30 and question in the chapter
      }
      else {
        maxQ = Math.min(StaticTags.MAX_QUESTION_SUBCATEGORY, selectedSubCat.value.questions_count) // maxQ will be the minimum of 50 and question in the sub category
      }

      if (questionNumber.value > maxQ) {
        showErrorOfQnumber.value = true
      }

      else if (time.value > offset * maxQ) {
        showErrorOfTime.value = true
      }
      else {
        showErrorOfQnumber.value = false
        showErrorOfTime.value = false
        if (isExamOnChap.value) {
          startExamOnChapter()
        }
        else {
          startExamOnSubcategory()
        }

      }

    }

    const goToPages = (chapter) => {
      // set topic before going to pages
      setExamParams({
        topic: StaticTags.TOPIC_FOR_CHAPTERS,
        topicId: chapter.id
      })
      store.dispatch(StaticTags.DISPATCH_ID_PAGE_ITEMS, JSON.stringify(chapter))
      router.push({
        name: flowType == StaticTags.TYPE_FAVOURITE ? URLs.ROUTE_FAVOURITE_PAGE_NAME : URLs.ROUTE_EXERCISE_PAGE_NAME,
        params: {
          cid: route.params.cid,
          sid: route.params.sid,
          chid: selectedChapter.value.id,
        },

      })
    }
    const startExerciseOnChapter = (chapter) => {
      setExamInfo({
        id: chapter.id,
        examType: StaticTags.EXAM_TYPE_CHAPTERS,
        title: chapter.name,
        isViewOnly: true,
        questionNumber: chapter.questions_count,
        isExam: false,
        examFlowType: flowType,
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_CHAPTERS,
        topicId: chapter.id,
        questionNumber: chapter.questions_count
      })

      router.push({
        name: URLs.ROUTE_EXAM_NAME,
      })
    }
    const startFavouriteOnChapter = (chapter) => {
      setExamInfo({
        id: chapter.id,
        examType: StaticTags.EXAM_TYPE_CHAPTERS,
        title: chapter.name,
        isViewOnly: true,
        questionNumber: chapter.marked,
        isExam: false,
        examFlowType: flowType,
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_CHAPTERS,
        topicId: chapter.id,
        questionNumber: chapter.marked
      })

      router.push({
        name: URLs.ROUTE_EXAM_NAME,
      })
    }
    const startExamOnChapter = () => {
      setExamInfo({
        id: selectedChapter.value.id,
        examType: StaticTags.EXAM_TYPE_CHAPTERS,
        title: selectedChapter.value.name,
        isViewOnly: false,
        questionNumber: questionNumber.value,
        isExam: true,
        startTime: new Date(),
        time: time.value,
        examFlowType: flowType
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_CHAPTERS,
        topicId: selectedChapter.value.id,
        questionNumber: questionNumber.value,
        time: time.value
      })
      router.push({
        name: URLs.ROUTE_EXAM_NAME,
      })

    };

    const startExamOnSubcategory = () => {
      setExamInfo({
        id: selectedSubCat.value.id,
        examType: StaticTags.EXAM_TYPE_SUB_CATEGORY,
        title: selectedSubCat.value.name,
        isViewOnly: false,
        questionNumber: questionNumber.value,
        isExam: true,
        startTime: new Date(),
        time: time.value,
        examFlowType: flowType
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_SUBCATEGORY,
        topicId: selectedSubCat.value.id,
        questionNumber: questionNumber.value
      })
      router.push({
        name: URLs.ROUTE_EXAM_NAME,
      })
    };



    return {
      chapterList,
      startExamOnChapter,
      startExamOnSubcategory,
      showModal,
      modalActive,
      toggleModal,
      questionNumber,
      time,
      checkFields,
      showErrorOfQnumber,
      showErrorOfTime,
      onChapterClick,
      isExamMode,
      selectALl,
      chapterName,
      title,
      flowType,
    };
  },
});
</script>

    

   

<style></style>