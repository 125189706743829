<template>
  <div class="subjective-list-button" >
    <div class="subjective-list-button-icon">
      {{ sign }}
    </div>
    <div class=" subjective-list-button-title">
      <p>{{ name }}</p>
      <p v-if="from_page" class="subjective-list-sub-title">{{pageText}}</p>
      <div v-else>
        <p v-if="flowType == 3" class="subjective-list-sub-title"> মার্কড প্রশ্ন সংখ্যা: {{ englishToBengaliNumber(marked) }}</p>
        <p v-else class="subjective-list-sub-title">প্রশ্ন সংখ্যা: {{ englishToBengaliNumber(questions_count) }}</p>
      </div>
    </div>
    <div class="subjective-list-button-arrow">
      <i class='fas fa-angle-right'></i>
    </div>

  </div>


</template>

<script>

import { englishToBengaliNumber } from "@/config/utils";

export default {
    props: [
      'flowType',
      'name',
      'sign',
      'questions_count',
      'marked',
      'from_page',
      'pageText',
    ],
  setup( ){
    return {
      englishToBengaliNumber,
    }
  }
}
</script>

<style scoped>

.subjective-list-button {
  display: none;
}

.left-align {
    text-align: left;
    text-align: left;
    margin-left: 1rem;

}

.align-center{
    align-items: center;
}

.top-div {
    margin-top: 0.06rem;
    background-color: rgba(17, 16, 16, 0.842);
}

.img-fluid {
    max-width: 65%;
    height: auto;
}

.subjective-list-button {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
  opacity: 0.9599999785423279;
  height: 80px;
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1.1fr 2fr 1fr;
  grid-gap: 12px;
}

.subjective-list-button-icon {
  background: #fff5f6;
  border-radius: 10%;
  width: 60px;
  height: 60px;
  position: relative;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
}

.subjective-list-box {
  height: 245px;
  width: 258px;
  border-radius: 18px;
  border: 2px solid rgb(0 0 0 / 5%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.subjective-list-rectangle {
  border-radius: 12px;
  position: relative;
  overflow: visible;
  grid-column: 1/5;
  margin: 18px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subjective-list-title {
  color: #071833;
  text-align: left;
  font: 600 16px/18px "Noto Sans Bengali", sans-serif;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  grid-column: 1/4;
  margin-left: 18px;

}
.subjective-list-button-title {
  color: #071833;
  text-align: left;
  font: 600 15px/19px "Noto Sans Bengali", sans-serif;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  grid-column: 2/4;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
}
.subjective-list-sub-title {
  position: relative;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #999999;

}

.subjective-list-arrow {
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  grid-column: -1;
}

.subjective-list-button-arrow {
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  grid-column: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subjective-list-arrow {
  overflow: visible;
}
.subjective-list-rectangle {
  background: #EDF1F7 ;
}

.subjective-list .subjective-list-box:nth-child(odd) .subjective-list-rectangle {
  background: #EDF1F7 ;
}
.subjective-list .subjective-list-box:nth-child(even) .subjective-list-rectangle {
  background: #F3D8D9 ;
}

.subjective-list-mobile .subjective-list-button:nth-child(odd) .subjective-list-button-icon {
  background: #EDF1F7 ;
}
.subjective-list-mobile .subjective-list-button:nth-child(even) .subjective-list-button-icon {
  background: #F3D8D9 ;
}
</style>