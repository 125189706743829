/**
 * ExamInformation file contains all the state or info
 * regarding exam type 
 * 
 */


export default class ExamInformation {
    constructor(
        title,
        examType,
        page,
        examId = 0,
        isExam = false,
        isViewOnly = false,
        total = 0,
        examTime = 0,
        startTime = -1,
        endTime = -1,
        examFlowtype,
        isRunningExam = false,
    ) {
         this.title = title
         this.examId = examId
         this.examType = examType
         this.isExam = isExam
         this. isViewOnly = isViewOnly
         this.total = total
         this.examTime = examTime
         this.startTime = startTime
         this.endTime = endTime
         this.page = page
         this.examFlowtype = examFlowtype
         this.isRunningExam = isRunningExam
    }

    build() {
        return new ExamInformation(
            this.title,this.examType,this.page,this.examId,this.isExam,this.isViewOnly,
            this.total, this.examTime,this.startTime,this.endTime,this.page, this.examFlowtype, this.isRunningExam
        )
    }

    setTitle(title) {
        this.title = title
        return this
    }
    setExamType(examType)
    {
        this.examType = examType
        return this
    }
    setExamId(examId) {
        this.examId = examId
        return this
    }
    setisExam(isExam)
    {
        this.isExam = isExam
        return this
    }
    setisViewOnly(isViewOnly) {
        this.isViewOnly = isViewOnly
        return this
    }
    setTotal(total)
    {
        this.total = total
        return this
    }
    setExamTime(examTime) {
        this.examTime = examTime
        return this
    }
    setStartTime(startTime)
    {
        this.startTime = startTime
        return this
    }
    setEndTime(endTime) {
        this.endTime = endTime
        return this
    }
    setPage(page)
    {
        this.page = page
        return this
    }

    setExamFlowType(examFlowtype)
    {
        this.examFlowtype = examFlowtype
        return this
    }

    setIsRunningExam(isRunningExam)
    {
        this.isRunningExam = isRunningExam
        return this
    }

}