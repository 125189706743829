<template>
  <div>
    <div v-bind:class="{ 'overlay-m': visible }"></div>
    <!-- <div class="loader" :class="{ 'loader--visible': visible }"> -->
    <!-- <div class="ld"> -->
    <pulse-loader :loading="visible"></pulse-loader>
    <!-- </div> -->
    <!-- </div> -->
  </div>
</template>
<script>
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  
  props: ["visible"],
  components: {
    PulseLoader,
  },
};
</script>
<style lang="scss">
.overlay-m {
  position: fixed;
  z-index: 1049;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.loader {
  background: rgba(255, 255, 255, 0.8);
  transition: 0.15s ease-in opacity;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
  pointer-events: none;
  opacity: 0;
  &--visible {
    opacity: 1;
    pointer-events: all;
  }
}
.ld {
  position: absolute;
  top: 50%;
  left: 50%;
}
</style>
