<template>
  <Header />
    <div class="container-fluid">
        <div class="container-fluid pl-0 pr-0">
            <div v-if="purchaseHistory" class="rank-list">
                <div class="row">
                    <div class="col-lg-2 col-md-2 col-sm-2" ></div>
                    <div class="col-lg-8 col-md-8 col-sm-8">
                        <table class="leader-board-participants table table-responsive-md text-center">
                            <thead>
                            <th colspan="2"> <h5>ট্রানজেকশন হিস্টরি</h5></th>
                            </thead>
                            <tbody>
                            <tr  v-for="(item, index) in purchaseHistory" :key="index">
                                <td class="text-left">
                                    <span class="username">{{ item.package_name }}</span><br>
                                    <span><small>ট্রান. আইডি: {{ item.tran_id }} </small></span>
                                </td>
                                <td class="text-right" >
                                    <span> {{ item.tran_date }} </span><br>
                                    <span> ৳{{ item.taka }} </span><br>
                                    <span> {{ item.status }} </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-lg-2 col-md-2 col-sm-2" ></div>
                </div>
            </div>
        </div>

        <div style="padding-top: 40px">
        </div>
        <div ref="footer">
            <Footer />
        </div>
    </div>
</template>
  
<script>
// import Header from "@/components/HeaderView.vue";
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import { onMounted, ref } from 'vue'
import Swal from 'sweetalert2/dist/sweetalert2';
import httpClient from "@/config/httpClient";
import { StaticTags } from "@/static";

export default {
    components: {
        Footer,
        Header,
    },

    setup() {

        const purchaseHistory = ref(null)
        onMounted(() => {
            getPurchaseHistory()
        })

        const getPurchaseHistory = async () => {
            await httpClient
                .get(process.env.VUE_APP_API_BASE_URL + "/memberships/lendens")
                .then((res) => {
                    if (res.success) {
                        
                        purchaseHistory.value = res.lendens
                    }
                    else {
                        Swal.fire({
                            text: res.message,
                            title: "Failed",
                            icon: StaticTags.ALERT_TYPE_ERROR
                        })
                    }


                })
        }
        return {
            purchaseHistory,
        }
    }
}
</script>
  
<style>
.first {
    color: green;
}

.second {
    color: blue;
}

.third {
    color: orange;
}

.rank-list {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;
    padding: 1rem;
}

.card {
    border: 1px solid #ccc;
    border-radius: 0.25rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card-title {
    margin-bottom: 0.5rem;
    font-size: 1.25rem;
    font-weight: bold;
}

.card-text {
    margin-bottom: 0.25rem;
    font-size: 1rem;
}

.leader-board-participants {
    margin: 20px 0 0 0;
    padding-left: 10px;
    padding-bottom: 10px;
}

.leader-board-participants tr {
    border-radius: 20px;
    padding: 5px 20px 5px 5px;
    margin-bottom: 10px;
    position: relative;
    background: #f1f1f1;
}

.leader-board-participants tr .username {
    display: inline-block;
    vertical-align: sub;
}
.leader-board-participants tr:nth-child(even) {
    background: whitesmoke;
    color: black;
}

.leader-board-participants tr:nth-child(odd) {
    background: white;
    color: #0a0a0a;
}
</style>
  