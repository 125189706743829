<template>
  <div v-show="modalSyllabusActive" class="modal " >
    <div v-show="modalSyllabusActive">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <div class="modal-content-title-section">
              <h2 class="modal-content-title">{{ (exam)? exam.title : "" }}</h2>
              <h2 class="content-sub-title">
                প্রশ্ন {{(exam)? englishToBengaliNumber(exam.total): "" }} টি - সময় {{ (exam)? englishToBengaliNumber(exam.time): "" }} মিনিট
              </h2>
            </div>
          </div>
          <div class="modal-body">
            <div class="modal-content-syllabus">
              <p class="syllabus-title">সিলেবাস</p>
              <p class="syllabus-content"> {{(exam)? exam.syllabus_name: ""}}</p>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class=" close-btn btn btn-outline-danger" @click="closeSyllabusModal">বাতিল করুন</button>
            <button v-if="isStartBtn" type="button" class="start-btn btn btn-outline-primary" @click="startExam">শুরু করুন</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
  props: ["modalSyllabusActive", 'exam',"isStartBtn"],
  setup(props, {emit}) {

    const closeSyllabusModal = () => {
      emit("close")
    }

    const englishToBengaliNumber = (number) => {
      const bengaliNumbers = {
        '0': '০',
        '1': '১',
        '2': '২',
        '3': '৩',
        '4': '৪',
        '5': '৫',
        '6': '৬',
        '7': '৭',
        '8': '৮',
        '9': '৯'
      };

      const englishNumber = String(number);
      const bengaliNumber = englishNumber.split('').map(digit => bengaliNumbers[digit] || digit).join('');

      return bengaliNumber;
    }

    const startExam = () => {
      emit("startExam")
    }
    return {
      closeSyllabusModal,
      startExam,
      englishToBengaliNumber,
    }
  }
})
</script>
  
<style lang="scss" scoped>

.modal-content-syllabus {
  text-align: justify;
}
.modal-content {
  border-radius: 24px;
}
.close-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}
.start-btn {
  width: 150px;
  height: 45px;
  flex-shrink: 0;
  border-radius: 24px;
}

.modal-header {
  text-align: center;
  justify-content: center;
  border-bottom: 0px solid #e9ecef;
}
.modal-footer {
  text-align: center;
  justify-content: center;
  border-top: 0px solid #e9ecef;
}
.modal-body {
  padding: 2rem;
}
.modal-content-title {
  color: #071833;
  font-family: Noto Sans Bengali;
  font-size: 25px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 107.143% */
}
.content-sub-title {
  color: #00A8A8;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 80% */
}
.syllabus-title {
  color: #2f2d2d;
  font-family: Noto Sans Bengali;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px; /* 60% */
  text-align: start;
}
.syllabus-content {
  color: #2f2d2d;
  font-family: Noto Sans Bengali;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(14 13 13 / 71%);
}

.modal-dialog {
  width: auto;
  height: 662px;
}

@media (min-width: 576px){
  .modal-dialog {
    max-width: 600px;
    margin: 1.75rem auto;
  }

}

</style>
  