import CryptoJS from 'crypto-js';

// Function to encrypt a given message
let prefixRegEx = new RegExp('^' + process.env.VUE_APP_PREFIX);
let suffixRegEx = new RegExp(process.env.VUE_APP_SUFFIX + '$');
export const encrypt = (message) => {
    const encryptedBytes = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPTED_KEY.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(0, parseInt(process.env.VUE_APP_MIDKPOINT)) + process.env.VUE_APP_ENCRYPTED_KEY.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(parseInt(process.env.VUE_APP_MIDKPOINT) + parseInt(process.env.VUE_APP_MIDDLE.length))), {
        iv: CryptoJS.enc.Base64.parse(process.env.VUE_APP_IV.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(0, parseInt(process.env.VUE_APP_MIDVPOINT)) + process.env.VUE_APP_IV.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(parseInt(process.env.VUE_APP_MIDVPOINT) + parseInt(process.env.VUE_APP_MIDDLE.length))),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return encryptedBytes.ciphertext.toString();
};

// Function to decrypt a given encrypted message
export const decrypt = (ciphertext) => {
    const cipherParams = CryptoJS.lib.CipherParams.create({
        ciphertext: CryptoJS.enc.Hex.parse(ciphertext)
    });
    const decryptedBytes = CryptoJS.AES.decrypt(cipherParams, CryptoJS.enc.Base64.parse(process.env.VUE_APP_ENCRYPTED_KEY.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(0, parseInt(process.env.VUE_APP_MIDKPOINT)) + process.env.VUE_APP_ENCRYPTED_KEY.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(parseInt(process.env.VUE_APP_MIDKPOINT) + parseInt(process.env.VUE_APP_MIDDLE.length))), {
        iv: CryptoJS.enc.Base64.parse(process.env.VUE_APP_IV.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(0, parseInt(process.env.VUE_APP_MIDVPOINT)) + process.env.VUE_APP_IV.replace(prefixRegEx, '').replace(suffixRegEx, '').slice(parseInt(process.env.VUE_APP_MIDVPOINT) + parseInt(process.env.VUE_APP_MIDDLE.length))),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.NoPadding
    });
    let decryptedText = decryptedBytes.toString(CryptoJS.enc.Utf8);
     decryptedText = decryptedText.replace(/\0/g, '');
    return decryptedText
};
