/**
 * this file is responsible to save the whole application state
 * regarding exam and question
 * 
 */

import { setStore, getStore } from "@/config/utils";
import ExamInformation from "@/dataModel/ExamInformation";
import { StaticTags } from '@/static/index.js'
const isExamRuning = getStore(StaticTags.IS_EXAM_RUNNING)
const exInfo = getStore(StaticTags.EXAM_INFORMATION)
const qInfo = getStore(StaticTags.QUESION_INFORMATION)
export const ExamRunningState = {
  namespaced: true,
  state: {
    isExamRuning: isExamRuning,
    examInformation: exInfo ? JSON.parse(exInfo): new ExamInformation(),
    questionInformationList: (qInfo) ? JSON.parse(qInfo) : null,
  },
  actions: {
    setExamRunning({ commit }, payload) {
      commit('setExamRunning', payload)

    },
    setExamInformation({ commit }, payload) {
      commit('setExamInformation', payload)
    },

    setQuestionInformation({ commit }, payload) {
      commit('setQuestionInformation', payload)
    }
  },
  mutations: {
    setExamRunning(state, payload) {
      state.isExamRuning = payload
      setStore(StaticTags.IS_EXAM_RUNNING, payload)
    },
    setExamInformation(state, payload) {
      state.examInformation = payload
      setStore(StaticTags.EXAM_INFORMATION, payload)
    },

    setQuestionInformation(state, payload) {
      state.questionInformationList = payload
      setStore(StaticTags.QUESION_INFORMATION, payload)
    },
  },
  getters: {
    isExamRuning: (state) => state.isExamRuning,
    getExamInformation: (state) => {
      
      if (typeof state.examInformation == "string") {
        state.examInformation = state.examInformation.substring(0, state.examInformation.length - 1);
        return JSON.parse(state.examInformation);
      }
      else
      {
        return state.examInformation
      }
     
    },
    getQuestionInformation: (state) => state.questionInformationList
  }
}; 