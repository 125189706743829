<template>
    <div>

        <h1>Oops</h1>
        <h3>You should start from home</h3>
      <p @click="goToHome" style="text-decoration: underline; cursor: pointer;">Go To Home</p>
  
    </div>
  </template>
  
  <style lang="scss" scoped>
  
  </style>
  
  <script>
  import { defineComponent, onMounted } from "vue";
  import { useRouter } from "vue-router";

  export default defineComponent({
    components: {
  
    },
  
    setup() {

      const router = useRouter();
      onMounted(() => {
  
      });
  
    
  
      function goToHome() {
        router.push("/");
      }
  
  
      return {
        goToHome,
      };
    },
  })
  </script>
  
  