<template>
  <!--      Header section start -->
  <Header/>
  <!--      Header section end -->

  <SubMenu :title="titleBar"></SubMenu>

  <StartExamModal @close="toggleModal" @start="checkFields" :modalActive="modalActive"
                  v-model:questionNumber="questionNumber" v-model:time="time" v-model:showErrorOfQnumber="showErrorOfQnumber"
                  v-model:showErrorOfTime="showErrorOfTime" :title="title">

  </StartExamModal>

  <div class="container-fluid mb-lg-5">
    <div class="subjective-test-container">
      <div class="subjective-title text-center" style="width: 100%; padding-left: 15px; padding-top: 40px">
        <h2 v-if="titleBar" class="top-title-div">{{titleBar}}</h2>
        <h2 v-else class="top-title-div">বিষয়ভিত্তিক পরীক্ষা</h2>
      </div>
      <div class="subjective-list">
        <BasicRow v-for="category in categoryList" :key="category.id" :name="category.name" :flowType="flowType"
                  :questions_count="category.questions_count" :sign="category.sign" :marked ="category.marked"
                  @click="onCategorySelected(category)">{{category}}
        </BasicRow>
      </div>

      <div class="subjective-list-mobile">
        <BasicRowMobile v-for="category in categoryList" :key="category.id" :name="category.name" :flowType="flowType"
                  :questions_count="category.questions_count" :sign="category.sign"  :marked ="category.marked"
                  @click="onCategorySelected(category)">
        </BasicRowMobile>
      </div>
    </div>
  </div>

  <div class="container-fluid">
    <!--      Footer section start -->
    <div ref="footer">
      <Footer />
    </div>
    <!--      Footer section end -->
  </div>
</template>

<script>

import {defineComponent, onMounted, ref} from "vue";
import BasicRow from "@/components/BasicRow.vue";
import { useRouter } from "vue-router";
import store from "@/store";
import { StaticTags, URLs } from "@/static";
import { mapState } from "vuex";
import {calculateOffset, setExamInfo, setExamParams} from "@/config/utils";
import Swal from 'sweetalert2/dist/sweetalert2';
import Header from "@/components/NewHeaderView.vue";
import Footer from "@/components/FooterView.vue";
import BasicRowMobile from "@/components/BasicRowMobile.vue";
import SubMenu from "@/components/SubMenu.vue";
import StartExamModal from "@/components/StartExamModal.vue";

export default defineComponent({
  components: {StartExamModal, SubMenu, BasicRow, BasicRowMobile, Footer, Header},

  watch: {
    categoryList() {
      return store.categoryList
    },
  },
  computed: mapState(["categoryList"]),
  created: function () {
    this.$store.dispatch("loadCategoryList"); // load data
  },
  setup() {
    let flowType = store.state.flowType
    const router = useRouter()
    const titleBar = ref( store.state.titleBar)
    const questionNumber = ref(30)
    const time = ref(18)
    const showErrorOfTime = ref(false)
    const showErrorOfQnumber = ref(false)
    const title = ref()
    const quiztime = ref(0)
    const selectedCat = ref()
    const modalActive = ref(false);
    // const categoryList = ref([])

    onMounted(() => {
      titleBar.value = store.state.titleBar ? store.state.titleBar : "বিষয়ভিত্তিক"
      // refresh full list as need marked question count for favourite
      if (flowType == StaticTags.TYPE_FAVOURITE) {
        store.dispatch('refreshCategoryList')
      }

    })

    const showModalForCategoryExam = () => {
      modalActive.value = true
    }

    const startExamOnCategory = () => {
      setExamInfo({
        id: selectedCat.value.id,
        examType: StaticTags.TYPE_OVER_ALL_EXAM,
        title: selectedCat.value.name,
        isViewOnly: false,
        questionNumber: questionNumber.value,
        isExam: true,
        startTime: new Date(),
        time: time.value,
        examFlowType: StaticTags.TYPE_OVER_ALL_EXAM
      })
      setExamParams({
        topic: StaticTags.TOPIC_FOR_CATEGORY,
        topicId: selectedCat.value.id,
        questionNumber: questionNumber.value
      })

      router.push("/exam");
    };


    const checkFields = () => {

      let offset = calculateOffset(quiztime)

      let maxQ = Infinity

      maxQ = Math.min(StaticTags.MAX_QUESTION_CATEGORY, selectedCat.value.questions_count) // maxQ will be the minimum of 50 and question in the sub category


      if (questionNumber.value > maxQ) {
        showErrorOfQnumber.value = true
      }
      //else if for checking time

      else if (time.value > offset * maxQ) {
        showErrorOfTime.value = true
      }
      else {
        showErrorOfQnumber.value = false
        showErrorOfTime.value = false
        startExamOnCategory()
      }

    }

    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };

    const onCategorySelected = (category) => {
      title.value = category.name
      quiztime.value = category.quiz_time
      selectedCat.value = category
      if (!flowType) {
        router.push({
          name: URLs.ROUTE_OOPS_PAGE_NAME
        })
      } else if (flowType == StaticTags.TYPE_FAVOURITE) {
        goToPages(category)
      } else {
        if (category.subcategories.length > 0)
        {
          goToSubCategories(category)
        } else {
          showModalForCategoryExam()
        }
      }

    }

    const goToSubCategories = (category) => {
      if(flowType == StaticTags.TYPE_EXAM){
        store.dispatch("setFlowType", StaticTags.TYPE_EXAM)
        router.push({
          name: URLs.ROUTE_EXAM_SUB_CATEGORIES_NAME,
          params: {
            cid: category.id,
          }
        })
      } else if(flowType == StaticTags.TYPE_EXERCISE){
        store.dispatch("setFlowType", StaticTags.TYPE_EXERCISE)
        router.push({
          name: URLs.ROUTE_EXERCISE_SUB_CATEGORIES_NAME,
          params: {
            cid: category.id,
          }
        })
      } else {
        Swal.fire({
          text: "Failed!",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
      }
    }

    // this function will only triggger for favourite type flow.
    const goToPages = (category) => {
      store.dispatch("setFlowType", StaticTags.TYPE_FAVOURITE)
      if (category.marked == undefined || category.marked <= 0) {
        Swal.fire({
          text: "No question marked!",
          title: "Failed",
          icon: StaticTags.ALERT_TYPE_ERROR
        })
      }
      // if needed to divide into page
      else if (category.subcategories.length > 1) {
        router.push({
          name: URLs.ROUTE_FAVOURITE_SUB_CATEGORIES_NAME,
          params: {
            cid: category.id,

          }
        })
      }
      else if (category.marked > StaticTags.QUESTION_LIMIT_OF_PAGE) {
        store.dispatch('setPageItemList', JSON.stringify(category))
        router.push({
          name: URLs.ROUTE_PAGE_NAME,
          params: {
            cid: category.id,
            sid: StaticTags.NO_ID,
            chid: StaticTags.NO_ID,
          },
        })
      }
      else {
        setExamInfo({
          id: category.id,
          examType: StaticTags.EXAM_TYPE_FAVOURITE,
          title: category.name,
          isViewOnly: false,
          questionNumber: category.marked,
          isExam: true,
          startTime: new Date(),
          examFlowType: flowType
        })
        setExamParams({
          topic: StaticTags.TOPIC_FOR_CATEGORY,
          topicId: category.id,
          questionNumber: category.marked,

        })
        router.push({
          name: URLs.ROUTE_EXAM_NAME,

        })
      }


    }


    return {
      flowType,
      titleBar,
      // categoryList,
      goToSubCategories,
      onCategorySelected,
      questionNumber,
      modalActive,
      showErrorOfTime,
      showErrorOfQnumber,
      toggleModal,
      checkFields,
      title,
      time,
    }
  },


});
</script>

<style scoped>



.subjective-list-button {
  background: #ffffff;
  border-radius: 16px;
  border-style: solid;
  border-color: #e6e4e4;
  border-width: 1px;
  opacity: 0.9599999785423279;
  height: 80px;
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  grid-template-columns: 1.1fr 2fr 1fr;
  grid-gap: 12px;
}

.subjective-list-button-icon {
  background: #fff5f6;
  border-radius: 10%;
  width: 60px;
  height: 60px;
  position: relative;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-column: 1;
}

.subjective-list-box {
  height: 245px;
  width: 258px;
  border-radius: 18px;
  border: 2px solid rgb(0 0 0 / 5%);
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.subjective-list-rectangle {
  border-radius: 12px;
  position: relative;
  overflow: visible;
  grid-column: 1/5;
  margin: 18px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subjective-list-title {
  color: #071833;
  text-align: left;
  font: 600 16px/18px "Noto Sans Bengali", sans-serif;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  grid-column: 1/4;
  margin-left: 18px;

}

.subjective-list-button-title {
  color: #071833;
  text-align: left;
  font: 600 15px/19px "Noto Sans Bengali", sans-serif;
  transform-origin: 0 0;
  transform: rotate(0deg) scale(1, 1);
  grid-column: 2/4;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 15px;
}

.subjective-list-sub-title {
  position: relative;
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 20px;
  color: #999999;

}

.subjective-list-arrow {
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  grid-column: -1;
}

.subjective-list-button-arrow {
  text-align: right;
  margin-right: 15px;
  cursor: pointer;
  grid-column: -1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subjective-list-arrow {
  overflow: visible;
}

.subjective-list-rectangle {
  background: #0095ff;
}

.subjective-list .subjective-list-box:nth-child(2n) .subjective-list-rectangle {
  background: #EDF1F7;
}

.subjective-list .subjective-list-box:nth-child(3n) .subjective-list-rectangle {
  background: #F3D8D9;
}

.subjective-list-mobile .subjective-list-button:nth-child(odd) .subjective-list-button-icon {
  background: #EDF1F7;
}

.subjective-list-mobile .subjective-list-button:nth-child(even) .subjective-list-button-icon {
  background: #F3D8D9;
}



</style>