/**
 * ExamInformation file contains all the state or info about question
 * like is that question's explanation show able or not
 * this class should be only used in homepage for creating
 * this class can be created using builder method
 */

export default class QuestionViewInformation {
    constructor(
        question,
        givenAnswer = 0,
        isMarked = false,
        isSeeAnswerButtonShowAble = false,
        isAnswerShowAble = false,
        isQuestionRemovable = false,
        isReportable = true,
        isShowMenu = true,
        isGoogleSearchAble =false,
        isExplanationShowAble = false,
        isEnable = true,
        isGivenAnswerShowAble = false,
        isStoreAbleInDB = false,
        autoIncrement = false,
        autoAnswerShowAble = false,
        isAllowMultipleAnswer = false,
        tag = '',
    )
    {
        this.givenAnswer = givenAnswer
        this.isMarked = isMarked
        this.isSeeAnswerButtonShowAble = isSeeAnswerButtonShowAble
        this.isAnswerShowAble = isAnswerShowAble
        this.isQuestionRemovable = isQuestionRemovable
        this.isReportable = isReportable
        this.isShowMenu = isShowMenu
        this.isGoogleSearchAble = isGoogleSearchAble
        this.isExplanationShowAble = isExplanationShowAble
        this.isEnable = isEnable
        this.isGivenAnswerShowAble = isGivenAnswerShowAble
        this.isStoreAbleInDB = isStoreAbleInDB
        this.autoIncrement = autoIncrement
        this.autoAnswerShowAble = autoAnswerShowAble
        this.isAllowMultipleAnswer = isAllowMultipleAnswer
        this.tag = tag
        this.question = question
    }
    build() {
        return new QuestionViewInformation(this.question, this.givenAnswer, this.isMarked,
            this.isSeeAnswerButtonShowAble, this.isAnswerShowAble, this.isQuestionRemovable,
            this.isReportable, this.isShowMenu, this.isGoogleSearchAble, this.isExplanationShowAble,
            this.isEnable, this.isGivenAnswerShowAble, this.isStoreAbleInDB, this.autoIncrement,
            this.autoAnswerShowAble, this.isAllowMultipleAnswer, this.tag
            )
    }
    setQuestion(question){
        this.question = question
        return this
    }
    setGivenAnswer(givenAnswer){
        this.givenAnswer = givenAnswer
        return this
    }
    setisMarked(isMarked){
        this.isMarked = isMarked
        return this
    }
    setisSeeAnswerButtonShowAble(isSeeAnswerButtonShowAble){
        this.isSeeAnswerButtonShowAble = isSeeAnswerButtonShowAble
        return this
    }
    setisAnswerShowAble(isAnswerShowAble){
        this.isAnswerShowAble = isAnswerShowAble
        return this
    }
    setisQuestionRemovable(isQuestionRemovable){
        this.isQuestionRemovable = isQuestionRemovable
        return this
    }
    setisReportable(isReportable){
        this.isReportable = isReportable
        return this
    }
    setisShowMenu(isShowMenu){
        this.isShowMenu = isShowMenu
        return this
    }
    setisGoogleSearchAble(isGoogleSearchAble){
        this.isGoogleSearchAble = isGoogleSearchAble
        return this
    }
    setisExplanationShowAble(isExplanationShowAble){
        this.isExplanationShowAble = isExplanationShowAble
        return this
    }
    setisEnable(isEnable){
        this.isEnable = isEnable
        return this
    }
    setisGivenAnswerShowAble(isGivenAnswerShowAble){
        this.isGivenAnswerShowAble = isGivenAnswerShowAble
        return this
    }
    setisStoreAbleInDB(isStoreAbleInDB){
        this.isStoreAbleInDB = isStoreAbleInDB
        return this
    }
    setAutoIncrement(autoIncrement){
        this.autoIncrement = autoIncrement
        return this
    }
    setAutoAnswerShowAble(autoAnswerShowAble){
        this.autoAnswerShowAble = autoAnswerShowAble
        return this
    }
    setisAllowMultipleAnswer(isAllowMultipleAnswer){
        this.isAllowMultipleAnswer = isAllowMultipleAnswer
        return this
    }
    setTag(tag){
        this.tag = tag
        return this
    }
}