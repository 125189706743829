<template>
    <div class="modal" v-if="showModal">
      <div class="modal-content">
        <span class="close" @click="closeModal">&times;</span>
        <h2>Update User Profile</h2>
        <form @submit.prevent="updateUser">
          <div>
            <label for="name">Name:</label>
            <input v-model="updatedUser.name" type="text" id="name" />
          </div>
          <div>
            <label for="email">Phone:</label>
            <input v-model="updatedUser.email" type="text" id="email"  disabled/>
          </div>
          <div>
            <label for="userId">Phone:</label>
            <input v-model="updatedUser.userId" type="text" id="userId" disabled />
          </div>
          <div>
            <label for="phone">Phone:</label>
            <input v-model="updatedUser.phone" type="text" id="phone" />
          </div>
          <button type="submit">Update</button>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import { defineComponent,ref } from 'vue';
  
  export default defineComponent({
    
    props: ["showModal","user",],

    setup(props,  { emit }) {

      const updatedUser = ref(props.user)

       const closeModal = ()=> {
        emit("onClose");
      }
      const updateUser = ()=>  {
        emit("onUpdate",updatedUser);
      }
      return{
        closeModal,
        updateUser,
        updatedUser,
      }
    }
  });
  </script>
  
  <style scoped>
  /* Styles for the modal */
  </style>
  