const log = {
  success: 'padding:0.6em 0; color:#27b376;',
  info: 'padding:0.6em 0; color:#3B5998;',
  error: 'padding:0.6em 0; color:#bf212f;'
};

const defaultLocale = 'en_us';

function fetchFbSdk(options) {
  const locale = options.locale ? options.locale : defaultLocale;
  return new Promise((resolve, reject) => {
    const script = document.createElement('script');
    script.src = 'https://connect.facebook.net/' + locale + '/sdk/xfbml.customerchat.js';
    script.onload = function() {
      resolve();
    };
    script.onerror = function() {
      reject();
      console.error('%cVueFbCustomerChat:: NOT loaded', log.error);
    };
    document.body.appendChild(script);
  })
}

function initFbSdk(options) {
  return new Promise(resolve => {
    window.fbAsyncInit = function() {
      const defaults = { cookie: true, xfbml: true, version: 'v5.0' };
      options = { ...defaults, ...options };
      window.FB.init(options);
      resolve();
    };
  })
}

function getFbSdk(options) {
  return new Promise(resolve => {
    if (window.FB) {
      resolve(window.FB);
    } else {
      fetchFbSdk(options).then(() => {
        initFbSdk(options).then(() => {
          resolve(window.FB);
        });
      });
    }
  })
}

function mountFbCustomerChat(options) {
  const elem = document.createElement('div');
  elem.setAttribute('class', 'fb-customerchat');
  elem.setAttribute('attribution', 'setup_tool');

  // set attributes
  Object.entries(options).forEach(attr => {
    elem.setAttribute(attr[0], attr[1]);
  });
  document.body.appendChild(elem);
}

const VueFbCustomerChat = {
  install: (app, options) => {
    app.config.globalProperties.fbCustomerChat = {
      setOptions(otherOptions) {
        options = { ...options, ...otherOptions };
      }
    };

    app.provide('$fbCustomerChat', app.config.globalProperties.fbCustomerChat);

    app.mixin({
      mounted() {
        if (!this.$parent) {
          getFbSdk(options).then(() => {
            if (options.page_id) {
              mountFbCustomerChat(options);
            } else {
              console.error(
                '%cVueFbCustomerChat:: You have to specify `page_id`',
                log.error
              );
            }
          });
        }
      }
    });
  }
};

// register plugin if it is used via cdn or directly as a script tag
if (typeof window !== 'undefined' && window.Vue) {
  window.VueFbCustomerChat = VueFbCustomerChat;
}

export default VueFbCustomerChat;
