<template>
  <div>
    <!-- <topHeader /> -->

    <LoaderView :visible="loading" />
    <router-view />
  </div>
</template>

<script>
//import TopHeader from './components/TopHeader.vue'
import store from '@/store/index.js';
import { StaticTags } from '@/static'
import { getStore  } from "@/config/utils.js"
import { mapState } from "vuex";
import LoaderView from "@/components/LoaderView.vue";
import { defineComponent, onMounted } from "vue";
//import Swal from 'sweetalert2/dist/sweetalert2';
//import httpClient from "@/config/httpClient";

export default defineComponent({
  computed: {
    ...mapState("loader", ["loading"]),
  },
  name: 'app',
  components: {
    //TopHeader,
    LoaderView,
  },

  setup() {

    // setting states based on data saved in local storage\

    if (getStore(StaticTags.IS_LOGGED_IN) && getStore(StaticTags.IS_LOGGED_IN) != "undefined") {
      store.dispatch('setAcessToken', getStore(StaticTags.IS_LOGGED_IN))
    }

    if (getStore(StaticTags.IS_EXAM_RUNNING && getStore(StaticTags.IS_EXAM_RUNNING) != "undefined")) {
      store.dispatch('ExamRunningState/setExamRunning', getStore(StaticTags.IS_EXAM_RUNNING))

    }
    if (getStore(StaticTags.EXAM_INFORMATION && getStore(StaticTags.EXAM_INFORMATION) != "undefined")) {
      store.dispatch('ExamRunningState/setExamInformation', getStore(StaticTags.EXAM_INFORMATION))
    }
    if (getStore(StaticTags.QUESION_INFORMATION) && getStore(StaticTags.QUESION_INFORMATION) != "undefined") {
      store.dispatch('ExamRunningState/setQuestionInformation', getStore(StaticTags.QUESION_INFORMATION))
    }


    onMounted(() => {
      // if (getStore(StaticTags.IS_LOGGED_IN) && getStore(StaticTags.IS_LOGGED_IN) !="null" && getStore(StaticTags.IS_LOGGED_IN) != "undefined") {
      //   getUserDetails()
      // }
    });

    // const getUserDetails = async() => {
    //   await httpClient
    //     //.post(baseUrl + "api2/login/", loginParams)
    //     .get(process.env.VUE_APP_API_BASE_URL + "/user-info")
    //     .then((response) => {
    //       if (response != undefined) {
    //         if (response.success) {
    //          setStore(StaticTags.USER_DETAILS,response.user)
    //         }

    //       }

    //     })
    // }

  }
})
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}


#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
