<template>
  <transition name="modal-animation">
    <div v-show="modalActive" class="modal">
      <transition name="modal-animation-inner">
        <div v-show="modalActive" class="modal-inner">

          <!-- Modal Content -->
          <slot />
          <div class="modal-content">
            <div class="modal-header">
              <h3>{{ question.question }}</h3>
              <span class="close" @click="$emit('close')">&times;</span>
            </div>
            <div class="modal-body">
              <label for="dropdown">Select your answer</label>
              <select id="dropdown" v-model="selectedOption">
                <option v-for="(option, index) in reportSelectionOption" :key="index" >{{ option }}</option>
                
              </select>
              <label for="text-input">Explanation Or Source</label>
              <input id="text-input" v-model="inputValue" type="text">
            </div>
            <div class="modal-footer">
              <button class="btn btn-primary" @click="okModal">OK</button>
              <button class="btn btn-secondary" @click="$emit('close')">Cancel</button>
            </div>
          </div>
        </div>
      </transition>
    </div>
</transition>
</template>
  
<script>
import { ref, onMounted } from 'vue';
export default {
  props: ["modalActive", "question", "dropdownOptions" ],
  setup(props, { emit }) {
    const questionOptions = ref(props.question.options)
    const extraOption = ["Incorrect Spelling", "Correct Answer Missing", "Old Question"]
    const selectedOption = ref();
    const inputValue = ref('');

    const reportSelectionOption = ref([])

    onMounted(() => {
      questionOptions.value = props.question.options
      questionOptions.value.forEach(element => {
        reportSelectionOption.value.push(element.choice[0].value)
      });
      reportSelectionOption.value = reportSelectionOption.value.concat(extraOption)
      selectedOption.value = reportSelectionOption.value[0]
      });
      

    const okModal = () => {
      const modalData = {
        selectedOption: selectedOption.value,
        inputValue: inputValue.value,
      };
      emit('submit', modalData);
    };


    return {
      selectedOption,
      inputValue,
      okModal,
      reportSelectionOption,
    };
   
  },
};
</script>
  
<style lang="scss" scoped>
.modal-animation-enter-active,
.modal-animation-leave-active {
  transition: opacity 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-enter-from,
.modal-animation-leave-to {
  opacity: 0;
}

.modal-animation-inner-enter-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02) 0.15s;
}

.modal-animation-inner-leave-active {
  transition: all 0.3s cubic-bezier(0.52, 0.02, 0.19, 1.02);
}

.modal-animation-inner-enter-from {
  opacity: 0;
  transform: scale(0.8);
}

.modal-animation-inner-leave-to {
  transform: scale(0.8);
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.7);

  .modal-inner {
    position: relative;
    max-width: 640px;
    width: 80%;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    background-color: rgb(49, 43, 43);
    padding: 64px 16px;

    i {
      position: absolute;
      top: 15px;
      right: 15px;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        color: crimson;
      }
    }

    .modal-content {
      background-color: #fefefe;
      margin: 15% auto;
      padding: 20px;
      border: 1px solid #888;
      width: 80%;

      .modal-header {
        display: flex;
        justify-content: space-between;

        h3 {
          margin: 0;
        }

        .close {
          cursor: pointer;
          font-size: 24px;
        }
      }

      .modal-body {
        display: flex;
        flex-direction: column;

        label {
          margin-bottom: 5px;
        }

        input,
        select {
          margin-bottom: 15px;
          padding: 10px;
          border-radius: 5px;
          border: 1px solid #ccc;
        }
      }

      .modal-footer {
        display: flex;
        justify-content: flex-end;

        .btn {
          margin-left: 10px;
          padding: 10px;
          border-radius: 5px;
          border: none;
          cursor: pointer;

          &.btn-primary {
            background-color: #007bff;
            color: #fff;
          }

          &.btn-secondary {
            background-color: #ccc;
            color: #333;
          }
        }
      }
    }
  }

  &.show {
    display: block;
  }
}
</style>