<template>
    <div v-if="state">
        <h2>Exam State</h2>
        <p>Exam: {{ state.exam }}</p>
        <p>Passed: {{ state.passed }}</p>
        <p>Total: {{ state.total }}</p>
        <p>Wrong: {{ state.wrong }}</p>
        <p>Correct: {{ state.correct }}</p>
        <p>Unanswered: {{ state.unanswered }}</p>
    </div>
</template>
  
<script>
import { defineComponent,onMounted, ref } from "vue";
import httpClient from "@/config/httpClient";
export default defineComponent({
    props: ["interval",],

    setup(props) {

        const state = ref()

        onMounted(() => {
            getAllExamState()
        })

        const getAllExamState = async() => {
            const url = process.env.VUE_APP_API_BASE_URL + "/statistics/result/category";

            await httpClient
                .get(url, {}, {
                    params: {
                        "interval": props.interval,
                    }
                })
                .then((res) => {
                    console.log(res);
                    if (res) {
                        state.value = res.state;
                    }
                })
        }

        return {
            state,
        };
    }
});
</script>
  